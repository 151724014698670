export const products = new Map([
  [
    1,
    {
      productName: "1500PR ",
      hamalrvatsSection: [
        "Քարշային մարտկոցներով",
        "Ամորտիզացման նոր համակարգով",
        "1500 Վտ հզորությամբ նոր էլեկտրաշարժիչով (ապահովում է 30% ավել պտտմանարդյունավետություն)",
        "15 դիոդ ունեցող առջևի լուսարձակով",
        "Լրացուցիչ նստատեղով՝ նախատեսված երկու ուղևորի համար",
        "Վարելու երեք ռեժիմով՝ դանդաղ, միջին, արագ",
        "Հետևի անիվների կատարելագործված ծածկադողերով",
      ],
      tableContents: {
        guyn: "Բաց մոխրագույն",
        sharjichHzorutyun: "1500 Վտ",
        martkoc: "XUPAI (60v-72AH)",
        martkocTesak: "Քարշային",
        chapser: "3070x1280x1412 մմ",
        tapqiChapser: "1500x1200x340 մմ",
        tricikaliQash: "240 կգ",
        bernataroxunakutyun: "750 կգ",
        maxSpeed: "35 կմ/ժ",
        mekLicqChanaparh: "80-90 կմ",
        azdHamakarg: "Այո",
        archevArgelak: "թմբուկային",
        hetevArgelak: "թմբուկային",
        qarshak: "Հետևի",
        mekLicqTevox: "8-10 ժամ",
        arjeviKaxoc: "Այո",
        heteviKaxoc: "Այո",
        archevAniv: "4,0 - R16",
        hetevAniv: "3,75 - R12",
        hidravlikAmortizator: "Այո",
        resor: "Այո",
        ponizheni: "Այո",
        lracucichNstategh: "Այո",
        artadroxErkir: "Չինաստան",
        erashxiq: "12 ամիս",
        shahagortsum: "Ամբողջ տարի",
      },
      imageName: "product1.png",
    },
  ],
  [
    2,
    {
      productName: "1200AD",
      hamalrvatsSection: [
        "Քարշային մարտկոցներով",
        "Ամորտիզացման նոր համակարգով",
        "1500 Վտ հզորությամբ նոր էլեկտրաշարժիչով (ապահովում է 30% ավել պտտմանարդյունավետություն)",
        "15 դիոդ ունեցող առջևի լուսարձակով",
        "Լրացուցիչ նստատեղով՝ նախատեսված երկու ուղևորի համար",
        "Վարելու երեք ռեժիմով՝ դանդաղ, միջին, արագ",
        "Հետևի անիվների կատարելագործված ծածկադողերով",
      ],
      tableContents: {
        guyn: "Խակի",
        sharjichHzorutyun: "1200 Վտ",
        martkoc: "XUPAI (60v-58AH)",
        martkocTesak: "Քարշային",
        chapser: "3070x1280x1412 մմ",
        tapqiChapser: "1500x1200x340 մմ",
        tricikaliQash: "240 կգ",
        bernataroxunakutyun: "750 կգ",
        maxSpeed: "35 կմ/ժ",
        mekLicqChanaparh: "65-70 կմ",
        azdHamakarg: "Այո",
        archevArgelak: "թմբուկային",
        hetevArgelak: "թմբուկային",
        qarshak: "Հետևի",
        mekLicqTevox: "8-10 ժամ",
        arjeviKaxoc: "Այո",
        heteviKaxoc: "Այո",
        archevAniv: "4,0 - R16",
        hetevAniv: "3,75 - R12",
        hidravlikAmortizator: "Այո",
        resor: "Այո",
        ponizheni: "Այո",
        lracucichNstategh: "Այո",
        artadroxErkir: "Չինաստան",
        erashxiq: "12 ամիս",
        shahagortsum: "Ամբողջ տարի",
      },
      imageName: "product2.png",
    },
  ],
  [
    3,
    {
      productName: "1000ST",
      hamalrvatsSection: [
        "Քարշային մարտկոցներով",
        "Ամորտիզացման նոր համակարգով",
        "1500 Վտ հզորությամբ նոր էլեկտրաշարժիչով (ապահովում է 30% ավել պտտմանարդյունավետություն)",
        "15 դիոդ ունեցող առջևի լուսարձակով",
        "Լրացուցիչ նստատեղով՝ նախատեսված երկու ուղևորի համար",
        "Վարելու երեք ռեժիմով՝ դանդաղ, միջին, արագ",
        "Հետևի անիվների կատարելագործված ծածկադողերով",
      ],
      tableContents: {
        guyn: "Վառ կապույտ",
        sharjichHzorutyun: "1000 Վտ",
        martkoc: "XUPAI (60v-45AH)",
        martkocTesak: "Քարշային",
        chapser: "2985x1180x1360 մմ",
        tapqiChapser: "1500x1100x490 մմ",
        tricikaliQash: "240 կգ",
        bernataroxunakutyun: "500 կգ",
        maxSpeed: "30 կմ/ժ",
        mekLicqChanaparh: "55-65 կմ",
        azdHamakarg: "Այո",
        archevArgelak: "թմբուկային",
        hetevArgelak: "թմբուկային",
        qarshak: "Հետևի",
        mekLicqTevox: "8-10 ժամ",
        arjeviKaxoc: "Այո",
        heteviKaxoc: "Այո",
        archevAniv: "3,75 - R16",
        hetevAniv: "3,75 - R12",
        hidravlikAmortizator: "Այո",
        resor: "Այո",
        ponizheni: "Այո",
        lracucichNstategh: "Այո",
        artadroxErkir: "Չինաստան",
        erashxiq: "12 ամիս",
        shahagortsum: "Ամբողջ տարի",
      },
      imageName: "product3.png",
    },
  ],
]);
